import Vue from "vue";
import VueRouter from "vue-router";
const Login = () => import("./components/Login");
const Route = () => import("./components/Route");
const Project = () => import("./components/Project");
const Document = () => import("./components/Document");
const SystemConfig = () => import("./components/SystemConfig");

const locationPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return locationPush.call(this, location).catch((err) => {
    return new Error(err);
  });
};
Vue.use(VueRouter);

const routes = [
  { path: "/login", component: Login },
  { path: "/Route", component: Route },
  { path: "/Project", component: Project },
  { path: "/Document", component: Document },
  { path: "/SystemConfig", component: SystemConfig },
  { path: "/", component: Route },
];

const router = new VueRouter({
  routes,
});

export default router;
